import {animate, state, style, transition, trigger} from '@angular/animations';

export const FADE_IN = trigger(
  'fadeIn', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('0.5s ease-in', style({ opacity: 1 }))
    ]),
  ],
);

export const FADE_OUT = trigger(
  'fadeOut', [
    transition(':leave', [
      style({ opacity: 1 }),
      animate('0.5s ease-in', style({ opacity: 0 }))
    ]),
  ],
);

export const ROLL_IN = trigger(
  'rollIn', [
    transition(':enter', [
      style({
        display: 'relative',
        height: 0,
        overflow: 'hidden',
      }),
      animate('0.4s ease-in', style({display: 'block', height: '*'}))
    ]),
  ],
);

export const SLIDE_LEFT = trigger(
  'open', [
    state('opened', style({
      left: '0%',
    })),
    state('closed', style({
      left: '100%',
    })),
    transition('opened <=> closed', [animate('0.2s ease-in')]),
  ],
);

export const TURN_OVER = trigger(
  'turnOver', [
    state('level', style({
      transform: 'rotateX(0deg)',
    })),
    state('turned', style({
      transform: 'rotateX(180deg)',
    })),
    transition('level <=> turned', [animate('0.3s ease-in')]),
  ],
);
